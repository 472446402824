
import { defineComponent } from "vue";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import DropDown from "primevue/dropdown";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import store from "@/store/index";
export default defineComponent({
  name: "PartModal",
  methods: {
    search() {
      store
        .dispatch("inventory/fetchInventory", {
          id: this.partNum,
          correls: "um",
        })
        .then((response) => {
          if (response.invloc_items) {
            this.locations = response.invloc_items.map((item: any) => {
              return item.invloc;
            });
          }
        });
    },
  },
  components: {
    InputNumber,
    InputText,
    Button,
    DropDown,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      partNum: "",
      selectedLocation: "",
      partQuantity: "",
      locations: [],
      lots: null,
      bins: null,
    };
  },
  validations() {
    return {
      partNum: { required },
    };
  },
  props: {
    submit: Function,
  },
});
